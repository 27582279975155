<template>
  <div class="fill-height">
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="showError">
      <v-icon color="white">mdi-alert-outline</v-icon>
      <span class="white--text pl-3">{{ error }}</span>
    </div>
    <v-container fluid class="container-main">
      <v-row>
        <v-col cols="12" md="8" xl="7">
          <h2>{{ title }}</h2>
          <p class="text-body-1 font-italic mb-1">
            '{{ name }}' submitted on {{ dateSubmittedDisplay }}
          </p>
          <p class="text-body-1 font-italic text--secondary">
            {{ note }}
          </p>
          <submit-review
            v-if="isSubmitted"
            :name="userDisplay"
            :reviewer="author"
            :reviewId="id"
            :reviewUserId="userId"
            @error="handleError($event)"
          ></submit-review>
        </v-col>
        <v-col cols="12" md="4" xl="5">
          <div v-if="isSubmitted">
            <h3>Review Comments</h3>
            <p class="text-body-1 font-italic text--secondary">
              Click and drag on the resume to select an area and add a comment.
            </p>
            <p class="text-body-1 font-italic text--secondary">
              The add button below adds a comment with no highlighting.
            </p>
          </div>
          <div v-else>
            <h3>Review Status</h3>
            <p
              v-if="isWithdrawn"
              class="text-body-1 font-italic text--secondary"
            >
              This review was withdrawn by the user.
            </p>
            <p v-else class="text-body-1 font-italic text--secondary">
              This review was completed on {{ dateReviewedDisplay }} by
              {{ reviewerDisplay }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-system-bar color="accent" height="5" class="my-4"></v-system-bar>
      <review-comment
        :currentHref="`/ResumeReviewWriter/${userId}/${id}`"
        :imageRef="imageRef"
        :initialComments="comments"
        :errorOnLoad="errorOnLoad"
        :userId="userId"
        :reviewId="id"
        @error="handleError($event)"
        :editable="isSubmitted"
      ></review-comment>
    </v-container>
  </div>
</template>

<script>
import * as disp from "../../utility/display.js";
import * as dUtil from "../../utility/dateutil";
import SubmitReview from "../../components/SubmitReview.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import ReviewAndComment from "../../components/reviewer/ReviewAndComment.vue";

// @ is an alias to /src
export default {
  name: "ResumeReviewWriter",
  data: () => ({
    errorOnLoad: false,
    error: "",
  }),
  components: {
    "submit-review": SubmitReview,
    "review-comment": ReviewAndComment,
  },
  mounted() {
    let pageUser = this.$route.params.user;
    if (this.userId != pageUser) {
      this.userId = pageUser;
    }
    let pageId = this.$route.params.id;
    if (this.id != pageId) {
      this.id = pageId;
    }

    this.setErrorOnLoad();

    this.loadReviewAdmin({ userId: this.userId, reviewId: this.id }).then(
      this.setErrorOnLoad
    );
  },
  methods: {
    ...mapActions("currentReview", ["loadReviewAdmin"]),
    // construct a scaled image configuration - image can be more than a page long.
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    handleError(errorText) {
      this.error = errorText;
    },
    setErrorOnLoad() {
      if (!disp.IsNullorWhitespace(this.loadResumeError)) {
        this.errorOnLoad = true;
      } else {
        this.errorOnLoad = false;
      }
    },
  },
  computed: {
    author() {
      return this.userInfo.username;
    },
    title() {
      if (this.isSubmitted) {
        return `Review ${this.userDisplay}'s Resume`;
      } else if (this.isWithdrawn) {
        return `${this.userDisplay}'s Resume (Withdrawn)`;
      } else {
        return `${this.userDisplay}'s Resume (Review Completed)`;
      }
    },
    showError() {
      return !disp.IsNullorWhitespace(this.error);
    },
    isSubmitted() {
      return this.status.toLowerCase() === "submitted";
    },
    isWithdrawn() {
      return this.status.toLowerCase() === "withdrawn";
    },
    ...mapFields("currentReview", [
      "loadResumeError",
      "comments",
      "imageRef",
      "name",
      "note",
      "dateSubmitted",
      "userDisplay",
      "userId",
      "id",
      "status",
      "dateReviewed",
      "reviewerDisplay",
    ]),
    ...mapFields("auth", ["userInfo"]),
    dateSubmittedDisplay() {
      return dUtil.dateStringToFormat(this.dateSubmitted);
    },
    dateReviewedDisplay() {
      return dUtil.dateStringToFormat(this.dateReviewed);
    },
  },
};
</script>