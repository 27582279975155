<template>
  <v-row>
    <v-col cols="12" md="8" xl="7">
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center px-5"
          ref="stageContainer"
        >
          <div v-if="errorOnLoad">
            <div
              class="d-flex flex-column justify-center align-center error-box"
            >
              <div class="pb-5 error-box-content">
                <h1>Ouch!</h1>
                <h2>We couldn't load your resume!</h2>
              </div>
              <div class="error-box-content">
                <div class="font-italic pb-2">
                  We're looking into the problem - in the mean time you can try
                  reloading the page:
                </div>
                <v-btn @click="refresh(currentHref)" outlined>Refresh</v-btn>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="resume-backing pa-3 mb-4">
              <v-stage
                :config="stageConfig"
                @mousemove="handleStageMouseMove"
                @mousedown="handleStageMouseDown"
                @mouseup="handleStageMouseUp"
                :class="stageClass"
              >
                <v-layer :config="layerConfig">
                  <v-image
                    :config="{
                      image: resumeImage,
                      ...imageConfig,
                    }"
                  >
                  </v-image>
                  <v-rect :config="newSelection"></v-rect>
                  <v-rect
                    @mouseenter="handleMouseMove"
                    @mouseleave="handleMouseMove"
                    v-for="comment in localComments"
                    :key="comment.id"
                    :config="comment.config"
                  ></v-rect>
                </v-layer>
              </v-stage>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4" xl="5">
      <div v-if="editable">
        <div
          class="d-flex align-center justify-center pb-2"
          v-if="!showNewComment"
        >
          <v-btn
            block
            text
            outlined
            tile
            elevation="1"
            @click="newUnlinkedComment()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-card tile elevation="1" class="mb-5" v-if="showNewComment">
          <v-card-text class="pt-1">
            <v-form @submit.prevent="addComment()">
              <v-textarea
                ref="newComment"
                v-model="newComment"
                label="New Comment"
                rows="3"
              ></v-textarea>
              <div class="d-flex justify-space-between">
                <v-btn type="submit" outlined>Save Comment</v-btn>
                <v-btn outlined @click="cancelComment">Cancel</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
      <div v-if="commentCount > 0">
        <v-card
          tile
          elevation="1"
          v-for="comment in localComments"
          :key="comment.id"
          :class="commentClass(comment.highlight)"
          @mouseenter="handleCardMouseMove(comment.id)"
          @mouseleave="handleCardMouseMove(comment.id)"
        >
          <div class="pt-3 pb-3 px-4 d-flex justify-space-between">
            <div>
              <div>{{ comment.text }}</div>
              <div class="text-body-2 text--secondary">
                {{ comment.author }}
              </div>
            </div>
            <v-btn
              v-if="editable"
              icon
              class="mt-n1 mr-n3"
              @click="removeComment(comment.id)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card>
      </div>
      <div v-else-if="!editable">
        <p class="text-body-1 font-italic text--secondary">No comments yet</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import * as uuid from "../../utility/guid";
import * as adminApi from "../../api/CareerApiAdmin";
import * as log from "../../utility/logging/logger";

// @ is an alias to /src
export default {
  name: "ReviewAndComment",
  data: () => ({
    stageConfig: {
      width: 612,
      height: 792,
    },
    refStageConfig: {
      width: 612,
      height: 792,
    },
    stageWidth: 612,
    resizeTimeout: null,
    localComments: [],
    layerConfig: {},
    newSelection: {},
    newComment: "",
    showNewComment: false,
    isDrawing: false,
    resumeImage: null,
    imageConfig: {},
    unselectedFill: "#df202530",
    selectedFill: "#df202578",
  }),
  props: {
    imageRef: {
      type: String,
    },
    currentHref: {
      type: String,
    },
    initialComments: {
      type: Array,
      default: () => [],
    },
    errorOnLoad: {
      type: Boolean,
    },
    userId: {
      type: String,
    },
    reviewId: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stageClass() {
      if (this.editable) {
        return "cursor-select";
      }
      return "";
    },
    commentCount() {
      return this.localComments?.length ?? 0;
    },
  },
  mounted() {},
  watch: {
    initialComments: {
      handler() {
        this.setupAll();
      },
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.setupAll();
      }, 100);
    },
    setupAll() {
      let maxWidth = this.$refs.stageContainer.offsetWidth;
      let hwr = this.refStageConfig.height / this.refStageConfig.width;

      // calc stage values
      let newStageWidth = maxWidth - 12 * 2;
      this.stageConfig.width = newStageWidth;
      this.stageConfig.height = newStageWidth * hwr;

      // reload image
      this.stageWidth = newStageWidth;
      this.loadImage();
      this.loadLocalComments();
      this.setupComments();
      this.resetNewSelection();
    },
    buildImageConfig(imgWidth, imgHeight, maxWidth) {
      const targetHeight = (maxWidth * imgHeight) / imgWidth;
      return { width: maxWidth, height: targetHeight };
    },
    loadLocalComments() {
      this.localComments = [];
      this.initialComments.forEach((c) => {
        var commentScale = this.stageWidth / c.config.initialWidth;
        let newObj = {
          authorDisplay: c.authorDisplay,
          authorId: c.authorId,
          edit: false,
          highlight: false,
          id: c.id,
          text: c.text,
          config: {
            height: c.config.height * commentScale,
            width: c.config.width * commentScale,
            x: c.config.x * commentScale,
            y: c.config.y * commentScale,
            fill: this.selectedFill,
            id: c.id,
          },
        };
        this.localComments.push(newObj);
      });
    },
    setupComments() {
      let i = 0;
      for (i = 0; i < this.localComments.length; i++) {
        let c = this.localComments[i];
        c.config.fill = this.unselectedFill;
        c.config.id = c.id;
        c.edit = false;
        c.highlight = false;
        this.$set(this.localComments, i, c);
      }
    },
    loadImage() {
      let image = new window.Image();
      image.src = this.imageRef;
      image.onload = () => {
        this.resumeImage = image;
        this.imageConfig = this.buildImageConfig(
          image.width,
          image.height,
          this.stageWidth
        );
        this.stageConfig.height = this.imageConfig.height;
      };
    },
    handleStageMouseMove(evt) {
      if (!this.editable) {
        return;
      }
      if (this.isDrawing) {
        let stage = evt.target.getStage();
        let pos = stage.getPointerPosition();

        this.newSelection.width = Math.round(pos.x) - this.newSelection.x;
        this.newSelection.height = Math.round(pos.y) - this.newSelection.y;
      }
    },
    handleStageMouseDown(evt) {
      if (!this.editable) {
        return;
      }
      if (this.isDrawing) {
        this.resetNewSelection();
        return;
      }

      let stage = evt.target.getStage();
      let pos = stage.getPointerPosition();

      this.newSelection.x = Math.round(pos.x);
      this.newSelection.y = Math.round(pos.y);
      this.newSelection.width = 0;
      this.newSelection.height = 0;
      this.newSelection.fill = this.selectedFill;

      this.isDrawing = true;
    },
    handleStageMouseUp() {
      if (!this.editable) {
        return;
      }
      if (!this.isDrawing) {
        this.resetNewSelection();
        return;
      }

      this.isDrawing = false;
      this.showNewComment = true;
      this.$nextTick(() => {
        this.$refs.newComment?.focus();
      });
    },
    async addComment() {
      if (!this.editable) {
        return;
      }
      let commentObj = {};
      commentObj.id = uuid.gen();
      commentObj.text = this.newComment;
      commentObj.edit = false;
      commentObj.author = this.author;
      commentObj.highlight = false;

      // default - no value
      commentObj.config = this.newSelection;
      commentObj.config.initialWidth = this.stageWidth;

      this.newComment = "";
      this.showNewComment = false;
      this.resetNewSelection();

      this.localComments.push(commentObj);
      this.setupComments();

      // Add comment
      await this.saveComment(
        this.userId,
        this.reviewId,
        commentObj.id,
        commentObj.config,
        commentObj.text
      );
    },
    async deleteComment(userId, reviewId, commentId) {
      if (!this.editable) {
        return;
      }
      try {
        var result = await adminApi.RemoveReviewComment(
          userId,
          reviewId,
          commentId
        );
        if (!result.success) {
          log.logError(
            `Error removing comment: ${JSON.stringify(result)}`,
            "ResumeReviewWriter.deleteComment"
          );
          this.$emit(
            "error",
            "Error removing comment. Try refreshing the page."
          );
        }
      } catch (er) {
        log.logError(
          `Error removing comment: ${er}`,
          "ResumeReviewWriter.deleteComment"
        );
        this.$emit("error", "Error removing comment. Try refreshing the page.");
      }
    },
    async saveComment(userId, reviewId, commentId, config, text) {
      if (!this.editable) {
        return;
      }
      try {
        var result = await adminApi.AddReviewComment(
          userId,
          reviewId,
          commentId,
          config,
          text
        );
        if (!result.success) {
          log.logError(
            `Error adding comment: ${JSON.stringify(result)}`,
            "ResumeReviewWriter.saveComment"
          );
          this.$emit("error", "Error adding comment. Try refreshing the page.");
        }
      } catch (er) {
        log.logError(
          `Error adding comment: ${er}`,
          "ResumeReviewWriter.saveComment"
        );
        this.$emit("error", "Error adding comment. Try refreshing the page.");
      }
    },
    cancelComment() {
      if (!this.editable) {
        return;
      }
      this.showNewComment = false;
      this.newComment = "";
      this.resetNewSelection();
    },
    handleMouseMove(evt) {
      let id = evt.target.attrs.id;
      this.highlightById(id);
    },
    handleCardMouseMove(id) {
      this.highlightById(id);
    },
    highlightById(id) {
      var i = 0;
      for (i = 0; i < this.localComments.length; i++) {
        let cNew = this.localComments[i];
        if (cNew.id === id) {
          cNew.highlight = true;
          cNew.config.fill = this.selectedFill;
          this.$set(this.localComments, i, cNew);
        } else if (
          cNew.highlight === true ||
          cNew.config.fill === this.selectedFill
        ) {
          cNew.highlight = false;
          cNew.config.fill = this.unselectedFill;
          this.$set(this.localComments, i, cNew);
        }
      }
    },
    commentClass(highlight) {
      if (highlight) {
        return "mb-3 comment-highlight";
      } else {
        return "mb-3";
      }
    },
    refresh(href) {
      log.logWarning(
        "User triggered refresh of Resume page due to error.",
        "Review.refresh"
      );
      window.location.href = href;
    },
    // create a new unlinked comment
    newUnlinkedComment() {
      if (!this.editable) {
        return;
      }
      this.resetNewSelection();
      this.showNewComment = true;
      this.$nextTick(() => {
        this.$refs.newComment?.focus();
      });
    },
    // remove a comment from local and server
    async removeComment(id) {
      if (!this.editable) {
        return;
      }
      this.localComments = this.localComments.filter((c) => c.id !== id);
      await this.deleteComment(this.userId, this.reviewId, id);
    },
    // reset newSelection, which is used for new comments
    resetNewSelection() {
      if (!this.editable) {
        return;
      }
      this.isDrawing = false;
      this.newSelection = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        fill: "",
        id: "",
      };
    },
  },
};
</script>

<style scoped>
.error-box {
  width: 636px;
  height: 816px;
  background-color: var(--v-resumeErrorBox-base);
  color: var(--v-resumeErrorText-base);
}

.error-box-content {
  width: 70%;
}

.resume-placeholder {
  width: 612px;
  height: 792px;
  background-color: var(--v-resumePlaceholder-base);
}

.resume-backing {
  background-color: var(--v-resumeBacker-base);
  /* max-width: 636px; */
}

.resume-img {
  max-width: 100%;
  height: auto;
}

.comment-highlight {
  background-color: #df202550;
}

.cursor-select {
  cursor: crosshair;
}
</style>